<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <!-- row 1 -->
                <b-row>
                  <b-col>
                    <base-input :label="$t('Order ID')">
                      <b-input type="text" v-model="order_id">
                      </b-input>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Date Type')">
                      <select class="form-control" v-model="date_type">
                        <option
                          v-for="(type, idx) in date_type_list"
                          :key="idx"
                          :value="type.IDn"
                        >
                          {{ type.Name }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('From Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        v-model='from_date'
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('To Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        v-model='to_date'
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                </b-row>
                <!-- row 2 -->
                <b-row>
                  <b-col>
                    <base-input :label="$t('Order Status')">
                      <select class="form-control" v-model="cur_status">
                        <option
                          v-for="(status, idx) in statuslist"
                          :key="idx"
                          :value="status"
                        >
                          {{ status }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Store')">
                      <select class="form-control" v-model="cur_store_id">
                        <option></option>
                        <option
                          v-for="(loc, idx) in storelist"
                          :key="idx"
                          :value="loc.LocationID"
                        >
                          {{ loc.LocationName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Company Name')">
                      <select class="form-control" v-model="cur_customer">
                        <option></option>
                        <option
                          v-for="(customer, idx) in customer_list"
                          :key="idx"
                          :value="customer.IDn"
                        >
                          {{ customer.CompanyName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col></b-col>
                </b-row>

                <b-row class="justify-content-lg-center">
                  <b-col class="text-center" lg="2">
                    <b-button variant="primary" @click="form_search">
                      {{ $t("Search") }}
                    </b-button>
                  </b-col>
                  <!-- <a v-bind:href="export_url" class="btn btn-primary" target="_blank">{{ $t("Export") }}</a>  -->
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-card>
        <b-card-body :style="{overflowX: 'auto' }">
          <div>
            <div v-if="list_data && list_data.length > 0">
              <div v-bind:style="{ width: '1000px' }">
                <b-row>
                  <b-col class="text-center" style="margin-bottom: 10px" lg="3">
                    <xlsx-workbook @change="change_for_report" @created="created_for_report">
                      <xlsx-sheet
                        :collection="sheet.data"
                        v-for="sheet in export_sheets_for_report"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                      />
                      <xlsx-download :filename="export_filename" :options="export_options" disable-wrapper-click>
                        <template #default="{ download }">
                          <b-button variant="primary" @click="export_for_edit(download)">
                            {{ $t("Export Excel") }}
                          </b-button>
                        </template>
                      </xlsx-download>
                    </xlsx-workbook>
                  </b-col>
                  <!-- <b-col class="text-center" lg="2">
                    <a v-bind:href="export_url" class="btn btn-primary" target="_blank">{{ $t("Export PDF") }}</a>
                  </b-col> -->
                </b-row>
                <!-- title -->
                <b-row style="margin-bottom: 10px">
                  <b-col><div v-bind:style="titleStyleObject">SO Order Summary</div></b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                </b-row>
                <b-row>
                  <b-col><div v-bind:style="titleStyleObject3">Store:</div></b-col>
                  <b-col><div v-bind:style="titleStyleObject3">Customer:</div></b-col>
                  <b-col><div v-bind:style="titleStyleObject3">Status:</div></b-col>
                  <b-col><div v-bind:style="titleStyleObject3">From:</div></b-col>
                  <b-col><div v-bind:style="titleStyleObject3">To:</div></b-col>
                  <b-col><div v-bind:style="titleStyleObject3">Print Date:</div></b-col>
                </b-row>
                <hr style="margin:0">
                <b-row>
                  <b-col><div v-bind:style="titleStyleObject4">{{ saved_store_name }}</div></b-col>
                  <b-col><div v-bind:style="titleStyleObject4">{{ saved_customer_name }}</div></b-col>
                  <b-col><div v-bind:style="titleStyleObject4">{{ saved_status }}</div></b-col>
                  <b-col><div v-bind:style="titleStyleObject4">{{ saved_from_date }}</div></b-col>
                  <b-col><div v-bind:style="titleStyleObject4">{{ saved_to_date }}</div></b-col>
                  <b-col><div v-bind:style="titleStyleObject4">{{ saved_datestr }}</div></b-col>
                </b-row>
                <hr style="margin:0">
              </div>
              <div v-bind:style="{ 'margin-top': '5px' }">
                <!-- head -->
                <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
                  <b-col><div v-bind:style="headerStyleObject">Order ID</div></b-col>
                  <b-col><div v-bind:style="headerStyleObjectLong">Order Date</div></b-col>
                  <b-col><div v-bind:style="headerStyleObjectLong">Company Name</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">Tax</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">SubTotal</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">Discount</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">Total</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">Status</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">Balance</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">Employee</div></b-col>
                </b-row>
                <!-- body -->
                <b-row :key='"so_" + key1' v-for='(so, key1) in list_data'>
                  <b-col><div v-bind:style="generalStyleObject">{{ so.OrderID }}</div></b-col>
                  <b-col><div v-bind:style="generalStyleObjectLong">{{ so.OrderDate.substr(0, 10) }}</div></b-col>
                  <b-col><div v-bind:style="generalStyleObjectLong">{{ so.SoldToName }}</div></b-col>
                  <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.TotalTax).toFixed(2) }}</div></b-col>
                  <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.SubTotal).toFixed(2) }}</div></b-col>
                  <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.soDiscount).toFixed(2) }}</div></b-col>
                  <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.TotalAmount).toFixed(2) }}</div></b-col>
                  <b-col><div v-bind:style="generalStyleObject">{{ so.Status }}</div></b-col>
                  <b-col><div v-bind:style="generalStyleObject">{{ parseFloat(so.Balance).toFixed(2) }}</div></b-col>
                  <b-col><div v-bind:style="generalStyleObject">{{ so.OrderByName }}</div></b-col>
                </b-row> 
                <!-- total -->
                <b-row>
                  <b-col>
                    <hr style="border: 1px solid black;">
                  </b-col>
                </b-row>  
                <b-row>
                  <b-col><div v-bind:style="headerStyleObject">Total:</div></b-col>
                  <b-col><div v-bind:style="headerStyleObjectLong"></div></b-col>
                  <b-col><div v-bind:style="headerStyleObjectLong"></div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(total.Tax).toFixed(2) }}</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(total.Subtotal).toFixed(2) }}</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(total.Discount).toFixed(2) }}</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(total.Total).toFixed(2) }}</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject"></div></b-col>
                  <b-col><div v-bind:style="headerStyleObject">{{ parseFloat(total.Balance).toFixed(2) }}</div></b-col>
                  <b-col><div v-bind:style="headerStyleObject"></div></b-col>
                </b-row>
              </div>
            </div>
            <div v-else>
              {{ $t('No Records') }}
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { Table, TableColumn, Button, Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
// import SearchAutocomplete from "../Components/SearchAutocomplete";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [flatPicker.name]: flatPicker,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    // SearchAutocomplete,
  },
  data() {
    return {
      pickr_config: {
        dateFormat: "Y-m-d",
      },
      titleStyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '26px',
      },
      titleStyleObject2: {
        textAlign: 'left',
        'padding-top': '5px',
        fontWeight: 'bold',
        fontSize: '20px',
      },
      titleStyleObject3: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      titleStyleObject4: {
        textAlign: 'left',
        fontSize: '18px',
      },
      headerStyleObject: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      generalStyleObject: {
        textAlign: 'right',
        fontSize: '16px',
      },
      headerStyleObjectLong: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '18px',
        width: '150px'
      },
      generalStyleObjectLong: {
        textAlign: 'right',
        fontSize: '16px',
        width: '150px'
      },

      token: "",
      user_id: 0,
      export_sheets_for_report: [{ name: "so order summary", data: [[1,2,3,4],[5,6,7,8]] }],
      export_filename: '',
      export_options: {Sheets: {"so report": {'!cols': [{wch:20}, {wch:30}, {wch:40}, {wch:30}, {wch:10}, {wch:10}]}}},   // not work
      export_url: '',

      list_data: null,
      total: null,

      storelist: null,
      customer_list: [],
      statuslist: ['Draft', 'Pending', 'Complete', 'Deleted'],
      date_type_list: [{IDn: "Order", Name: 'Order Date'}, 
                       {IDn: "Invoice", Name: 'Invoice Date'}, 
                       {IDn: "Delivery", Name: 'Delivery Date'}],

      // search conditions
      cur_status: 'Complete',
      date_type: "Order",
      from_date: null,
      to_date: null,
      cur_store_id: null,
      cur_customer: null,
      order_id: '',
      
      saved_store_name: '',
      saved_customer_name: '',
      saved_datetype_name: '',
      saved_datestr: '',
      saved_status: '',
      saved_from_date: '',
      saved_to_date: '',
    };
  },
  watch: {},
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    async get_list_data() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("Status", this.cur_status);
      bodyFormData.append("cur_date_type", this.date_type);

      var url = "";
      if (axios.defaults.baseURL != undefined) {
        url = axios.defaults.baseURL;
      }
      url += "/Api/Web/SO_order_summary/pdf?";
      url += "token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&Status=" + this.cur_status;
      url += "&cur_date_type=" + this.date_type;

      if (this.from_date) {
        bodyFormData.append("start_dt", this.from_date);
        url += "&start_dt=" + this.from_date;
      }
      if (this.to_date) {
        bodyFormData.append("end_dt", this.to_date);
        url += "&end_dt=" + this.to_date;
      }
      if (this.cur_store_id) {
        bodyFormData.append("LocationID", this.cur_store_id);
        url += "&LocationID=" + this.cur_store_id;
      }
      if (this.cur_customer) {
        bodyFormData.append("SoldToCustomerIDn", this.cur_customer);
        url += "&SoldToCustomerIDn=" + this.cur_customer;
      }
      if (this.order_id) {
        bodyFormData.append("OrderID", this.order_id);
        url += "&OrderID=" + this.order_id;
      }
      
      this.save_conditions();
      url += "&saved_store_name=" + this.saved_store_name;
      url += "&saved_customer_name=" + this.saved_customer_name;
      this.export_url = url;

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/SO_order_summary/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.list_data = rt.data.list_data;
              that.total = rt.data.total;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async form_search() {
      this.get_list_data();
    },
    get_today() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    },
    async init_data() {
      this.from_date = this.get_today();
      this.to_date = this.get_today();
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      this.get_init_data();
    },
    async get_init_data() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/SO_order_summary",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.storelist;
              that.customer_list = rt.data.customer_list;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    change_for_report(sheet) {
      // change event will emit twice when sheets is set, xlsx sheets is set to empty at first event, then xlsx sheets is set to the new sheets.
      if (this.callback_for_report && sheet.SheetNames && sheet.SheetNames.length > 0) {
        this.callback_for_report();
      }
    },
    created_for_report() {
      this.callback_for_report = '';
    },
    export_for_edit(callback) {
      let datestr = new Date().toLocaleDateString();
      this.export_filename = 'SO Order Summary ' + datestr + '.xlsx';
      this.export_sheets_for_report[0].data = this.generate_excel_data();
      this.callback_for_report = callback;
    },
    save_conditions() {
      this.saved_store_name = 'All Store';
      if (this.cur_store_id) this.saved_store_name = this.storelist.hasOwnProperty(this.cur_store_id) ? this.storelist[this.cur_store_id].LocationName : 'Error Store';
      this.saved_customer_name = 'All Customer';
      if (this.cur_customer) this.saved_customer_name = this.customer_list.hasOwnProperty(this.cur_customer) ? this.customer_list[this.cur_customer].CompanyName : 'Error Customer';
      this.saved_datetype_name = '';
      switch (this.date_type) {
        case 'Order':
          this.saved_datetype_name = 'Order Date';
          break;
        case 'Invoice':
          this.saved_datetype_name = 'Invoice Date';
          break;
        case 'Delivery':
          this.saved_datetype_name = 'Delivery Date';
          break;
        default:
          break;
      }
      this.saved_datestr = new Date().toLocaleDateString();
      this.saved_status = this.cur_status;
      this.saved_from_date = this.from_date;
      this.saved_to_date = this.to_date;
    },
    generate_conditions() {
      // conditions
      let excel_data = [];
      excel_data.push(['Store:','Company:','DateType:','From:','To:','Status:','Print Date:']);
      excel_data.push([this.saved_store_name, this.saved_customer_name, this.saved_datetype_name, this.saved_from_date, this.saved_to_date, this.saved_status, this.saved_datestr]);
      return excel_data;
    },
    generate_excel_data() {
      // conditions
      let excel_data = this.generate_conditions();
      excel_data.push([]);
      
      // data
      let row = ['Order ID','Order Date','Company Name','Tax','SubTotal','Discount','Total','Status','Balance','Employee'];
      excel_data.push(row);
      for (var i = 0; i < this.list_data.length; i++) {
        let so = this.list_data[i];
        row = [];
        row.push(so.OrderID);
        row.push(so.OrderDate.substr(0, 10));
        row.push(so.SoldToName);
        row.push(parseFloat(so.TotalTax).toFixed(2));
        row.push(parseFloat(so.SubTotal).toFixed(2));
        row.push(parseFloat(so.soDiscount).toFixed(2));
        row.push(parseFloat(so.TotalAmount).toFixed(2));
        row.push(so.Status);
        row.push(parseFloat(so.Balance).toFixed(2));
        row.push(so.OrderByName);
        excel_data.push(row);
      }

      row = [];
      row.push('Total:');
      row.push('');
      row.push('');
      row.push(parseFloat(this.total.Tax).toFixed(2));
      row.push(parseFloat(this.total.Subtotal).toFixed(2));
      row.push(parseFloat(this.total.Discount).toFixed(2));
      row.push(parseFloat(this.total.Total).toFixed(2));
      row.push('');
      row.push(parseFloat(this.total.Balance).toFixed(2));
      row.push('');
      excel_data.push(row);

      return excel_data;
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
